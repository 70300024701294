// Here you can add other styles
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&family=Rubik:wght@300&display=swap');

.select-currency .react-autosuggest__suggestions-container {
  background-color: #181924;
}

.text-small {
  font-size: 10px;
}

.text-strong {
  font-weight: 600;
}

.ghostit {
  opacity: 0.7;
}

h2.swal2-title {
  font-size: 1.5rem;
}

.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.orderForm {
  .form-control {
    font-size: 16px;
  }
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: capitalize;
}

.error-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e55353;
}

.select-error {
  & > div {
    border-color: #e55353;
  }
  & > div:hover {
    border-color: #e55353;
  }
}

.currency-input-error {
  & > input {
    border-color: #e55353;
  }
}

.currency-input {
  input {
    height: 2.89rem !important;
    padding: 0.375rem 0.75rem !important;
    --cui-input-border-color: #d8dbe0;
  }
}

.react-select {
  & > div {
    min-height: 2.8rem;
    border-color: #d8dbe0 !important;
  }
}

.order-summary {
  text-align: left;
  font-size: 1rem;
}
.async-select {
  flex: 1;
  width: 100%;
  flex-grow: 1;
}

.smart-table tr td {
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
}

.smart-table tr {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.btn-danger {
  --cui-btn-bg: #e55353;
  --cui-btn-border-color: #e55353;
  --cui-btn-color: white;
  --cui-btn-hover-bg: #e96d6d;
  --cui-btn-hover-border-color: #e86464;
  --cui-btn-hover-color: #000015;
  --cui-btn-active-bg: #ea7575;
  --cui-btn-active-border-color: #e86464;
  --cui-btn-active-color: #000015;
  --cui-btn-disabled-bg: #e55353;
  --cui-btn-disabled-border-color: #e55353;
  --cui-btn-disabled-color: #000015;
  --cui-btn-shadow: rgba(229, 83, 83, 0.5);
}
.btn-info {
  --cui-btn-color: white;
}

.card,
.sidebar.sidebar-fixed,
.header.header-sticky {
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.form-control {
  height: 2.8em !important;
}

dl {
  display: grid;
  grid-template-columns: max-content auto;
  margin-bottom: 0 !important;
}

dt {
  grid-column-start: 1;
  padding: 0.4rem 0;
  padding-right: 1rem;
  line-height: 1rem;
  font-weight: normal !important;
}

dd {
  padding: 0.4rem 0;
  grid-column-start: 2;
  line-height: 1rem;
  font-weight: 600 !important;
}

.bborder {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--cui-card-border-color, rgba(0, 0, 21, 0.125));
}

.tborder {
  border-top: 1px solid var(--cui-card-border-color, rgba(0, 0, 21, 0.125));
}

.totalborder {
  border-top: 2px solid var(--cui-card-border-color, rgba(0, 0, 21, 0.5));
}

.lborder {
  border-left: 1px solid var(--cui-card-border-color, rgba(0, 0, 21, 0.125));
}

.allborder {
  border: 1px solid var(--cui-card-border-color, rgba(0, 0, 21, 0.125));
}

.rborder {
  border-right: 1px solid var(--cui-card-border-color, rgba(0, 0, 21, 0.125));
}

.btn {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px !important;
}

.sidebar-nav .nav-link.active {
  font-weight: 600;
}

.link {
  text-decoration: underline;
  font-weight: 600;
}

.stepCard {
  background-image: var(--cui-gradient);
}

.bg-primary-gradient {
  background: rgb(43, 131, 16);
  background: linear-gradient(
    90deg,
    rgba(43, 131, 16, 1) 0%,
    rgba(32, 93, 13, 1) 48%,
    rgba(67, 203, 25, 1) 100%
  );
}

.nav-underline .nav-link {
  border-bottom: 4px solid transparent;
}

.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 600;
}

.no-shadow {
  box-shadow: none !important;
}

.btn-success {
  --cui-btn-color: white !important;
}

.sidebar-nav .nav-group.show {
  background: var(--cui-sidebar-nav-group-bg, rgba(0, 0, 0, 0.1)) !important;
}

.text-right {
  text-align: right;
}

.uploadcare--widget__button_type_open {
  background: white;
  color: #181924;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px !important;
  border: 1px solid var(--cui-btn-border-color, transparent);
}

.delivery-confirmation {
  text-align: left;
  font-size: 1rem;
}

.map-container {
  position: relative;
  //height: 100vh;
  overflow: hidden;
  border-radius: inherit;
}
#map {
  width: 100%;
  height: 100%;
}

.geofeature-popup {
  padding: 0.5rem;
  min-width: 15rem;
  font-size: 14px;
  div {
    padding: 2px 0;
  }
}
